import request from '../utils/request.js'

//景点
export function jingdian(id) {
	return request({
		url: '/website/citiao/citiaoAttractionsDetail/detail/' + id,
		method: 'get',
	})
}


//学校
export function xuexiao(id) {
	return request({
		url: '/website/citiao/citiaoSchoolDetail/detail/' + id,
		method: 'get',
	})
}


//医院
export function yiyuan(id) {
	return request({
		url: '/website/citiao/citiaoHospitalDetail/detail/' + id,
		method: 'get',
	})
}


//名人
export function mingren(id) {
	return request({
		url: '/website/citiao/citiaoCelebrityDetail/detail/' + id,
		method: 'get',
	})
}

//博物馆
export function bowuguan(id) {
	return request({
		url: '/website/citiao/citiaoMuseumDetail/detail/' + id,
		method: 'get',
	})
}







